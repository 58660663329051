/* eslint-disable  */
const SWHelper = {
  checkTimeout: null,

  bcUpdate: new BroadcastChannel('update'),

  async checkForUpdate (isFirst = true) {
    const serviceWorker = navigator.serviceWorker;
    const registration = await serviceWorker.getRegistration();

    if (!registration) {
      return this.repeatCheckUpdate();
    }

    try {
      await registration.update();
    } catch (error) {
      console.error('[PWA] update failed... ', error);
    }

    if (registration.waiting && registration.active) {
      this.bcUpdate.postMessage('UPDATE_AVALIABLE');
    }

    if (isFirst) {
      registration.onupdatefound = () => {
        this.onUpdateFound(registration);
      }

      window.addEventListener('beforeunload', () => {
        if (!registration.waiting) return;
        registration.waiting.postMessage({ type: 'SKIP_WAITING' });
      });
    }

    this.repeatCheckUpdate(false);
  },

  onUpdateFound (registration) {
    if (!registration.installing) return;

    const newSw = registration.installing;

    newSw.onstatechange = (event) => {
      if (event.target.state !== 'installed') return;
      this.bcUpdate.postMessage('UPDATE_AVALIABLE');
    };
  },

  repeatCheckUpdate (isFirst) {
    if (this.checkTimeout) clearTimeout(this.checkTimeout);
    this.checkTimeout = setTimeout(() => this.checkForUpdate(isFirst), 60000 * 15); // 15 min
  },
};

export default async (context) => {
  window.addEventListener('offline', () => {
    context.store.commit('setOffline', true);
  });

  window.addEventListener('online', () => {
    context.store.commit('setOffline', false);
  });

  if ('serviceWorker' in navigator) {
    console.info('[PWA] serviceWorker is avaliable');
    await SWHelper.checkForUpdate();
  }
};
